<template>
  <b-card :title="title">
    <div slot="header" v-html="caption" class="float-left font-weight-bold" :class="headerClass" v-if="showHeader"></div>
    <div slot="header" class="float-right" v-if="exportable">
      <download-excel
        :name="`${caption || 'MinhasVendas'}.xls`"
        :fields="exportFields"
        :data="tableData">
          <i class="fa fa-download cursor-pointer" alt="Exportar" title="Exportar"></i>
      </download-excel>
    </div>
    <div class="alert alert-secondary text-center" v-if="items && items.length === 0">
      Nenhum resultado encontrado.
    </div>
    <b-table :dark="dark" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="md" :items="items" :fields="captions" :current-page="currentPage" :per-page="perPage" v-if="items && items.length > 0">
      <template slot="status" slot-scope="data">
        <b-badge :variant="getBadge(data.item.status)">{{data.item.status}}</b-badge>
      </template>
      <template slot="meta" slot-scope="data">
        <i v-if="data.item.meta !== null" class="fa fa-circle fa-lg" :class="getMeta(data.item.meta)"></i>
        <span v-else>-</span>
      </template>
      <template slot="descricao" slot-scope="data">
        {{data.item.cod ? `${lpad(data.item.cod, 2, '0')}. ` : ''}}{{data.item.descricao}}
      </template>
      <template slot="ordem" slot-scope="data">
        <span>{{lpad(data.item.ordem, 2, '0')}}&ordm;</span>
      </template>
    </b-table>
    <nav v-if="!noPagination">
      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="‹‹" next-text="››" hide-goto-end-buttons class="mb-1" />
    </nav>
    <b-row v-if="readMore">
      <b-col cols="12" sm="12" class="text-right">
        <b-link :to="readMore.link">{{ readMore.text }}</b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import leftPad from 'left-pad';
import moment from 'moment';
export default {
  name: 'c-table',
  inheritAttrs: false,
  props: {
    noPagination: {
      type: Boolean,
      default: false
    },
    readMore: {
      type: Object,
      default: () => {}
    },
    headerClass: {
      type: String,
      deafult: null
    },
    exportable: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    caption: {
      type: String,
      default: 'Tabela'
    },
    hover: {
      type: Boolean,
      default: false
    },
    striped: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: [Array, Function],
      default: () => []
    },
    fields: {
      type: [Array, Object],
      default: () => []
    },
    perPage: {
      type: Number,
      default: 25
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      currentPage: 1
    }
  },
  computed: {
    items: function() {
      const isCurrency = this.fields.filter(f => f.currency)
      const isNumeric = this.fields.filter(f => f.numeric)
      const isNumber = this.fields.filter(f => f.number)
      const isDatetime = this.fields.filter(f => f.datetime)

      const items = this.tableData.map(item => {
        for (const i in isCurrency) {
          if (isCurrency[i] && isCurrency[i].key && !/.+,/g.test(item[isCurrency[i].key])) {
            item[isCurrency[i].key] = this.formatPrice(item[isCurrency[i].key])
          }
        }
        for (const i in isNumeric) {
          if (isNumeric[i] && isNumeric[i].key && !/.+,/g.test(item[isNumeric[i].key])) {
            item[isNumeric[i].key] = Number(item[isNumeric[i].key] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})
          }
        }
        for (const i in isNumber) {
          if (isNumber[i] && isNumber[i].key && !/.+,/g.test(item[isNumber[i].key])) {
            item[isNumber[i].key] = Number(item[isNumber[i].key] || 0).toLocaleString('pt-BR')
          }
        }
        for (const i in isDatetime) {
          if (isDatetime[i] && isDatetime[i].key && !/.+,/g.test(item[isDatetime[i].key])) {
            item[isDatetime[i].key] = moment(item[isDatetime[i].key]).format('DD/MM/YYYY HH:mm')
          }
        }
        return item
      })
      return Array.isArray(items) ? items : items()
    },
    totalRows: function() { return this.getRowCount() },
    captions: function() { return this.fields },
    exportFields: function() {
      const obj = {};
      return this.fields.reduce((item, key) => {
        obj[key['label']] = key['key'];
        return obj
      }, {});
    }
  },
  methods: {
    getMeta (meta) {
      return meta === 1 ? 'meta-bronze'
        : meta === 2 ? 'meta-prata'
          : meta === 3 ? 'text-warning'
            : meta === 4 ? 'text-info' : 'fa-frown-o'
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getRowCount: function () {
      return this.items.length
    },
    formatPrice (value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    lpad(str, pad, l = '0') {
      return leftPad(str, pad, l)
    }
  }
}
</script>
