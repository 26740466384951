var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { title: _vm.title } },
    [
      _vm.showHeader
        ? _c("div", {
            staticClass: "float-left font-weight-bold",
            class: _vm.headerClass,
            attrs: { slot: "header" },
            domProps: { innerHTML: _vm._s(_vm.caption) },
            slot: "header"
          })
        : _vm._e(),
      _vm.exportable
        ? _c(
            "div",
            {
              staticClass: "float-right",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "download-excel",
                {
                  attrs: {
                    name: (_vm.caption || "MinhasVendas") + ".xls",
                    fields: _vm.exportFields,
                    data: _vm.tableData
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-download cursor-pointer",
                    attrs: { alt: "Exportar", title: "Exportar" }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.items && _vm.items.length === 0
        ? _c("div", { staticClass: "alert alert-secondary text-center" }, [
            _vm._v("\n    Nenhum resultado encontrado.\n  ")
          ])
        : _vm._e(),
      _vm.items && _vm.items.length > 0
        ? _c("b-table", {
            attrs: {
              dark: _vm.dark,
              hover: _vm.hover,
              striped: _vm.striped,
              bordered: _vm.bordered,
              small: _vm.small,
              fixed: _vm.fixed,
              responsive: "md",
              items: _vm.items,
              fields: _vm.captions,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "status",
                  fn: function(data) {
                    return [
                      _c(
                        "b-badge",
                        { attrs: { variant: _vm.getBadge(data.item.status) } },
                        [_vm._v(_vm._s(data.item.status))]
                      )
                    ]
                  }
                },
                {
                  key: "meta",
                  fn: function(data) {
                    return [
                      data.item.meta !== null
                        ? _c("i", {
                            staticClass: "fa fa-circle fa-lg",
                            class: _vm.getMeta(data.item.meta)
                          })
                        : _c("span", [_vm._v("-")])
                    ]
                  }
                },
                {
                  key: "descricao",
                  fn: function(data) {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            data.item.cod
                              ? _vm.lpad(data.item.cod, 2, "0") + ". "
                              : ""
                          ) +
                          _vm._s(data.item.descricao) +
                          "\n    "
                      )
                    ]
                  }
                },
                {
                  key: "ordem",
                  fn: function(data) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.lpad(data.item.ordem, 2, "0")) + "º")
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              1291121898
            )
          })
        : _vm._e(),
      !_vm.noPagination
        ? _c(
            "nav",
            [
              _c("b-pagination", {
                staticClass: "mb-1",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "prev-text": "‹‹",
                  "next-text": "››",
                  "hide-goto-end-buttons": ""
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.readMore
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { cols: "12", sm: "12" } },
                [
                  _c("b-link", { attrs: { to: _vm.readMore.link } }, [
                    _vm._v(_vm._s(_vm.readMore.text))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }